import Vue from "vue";
import "bootstrap";
import VueScrollClass from "vue-scroll-class";
const carousels = require("./carousels");

const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
    isQuestionModal: true,
    isWebMenuModal: false,
    isActive: "1",
  },
  mounted() {
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();
    // show_modalパラメータを削除
    const url = new URL(window.location.href);
    url.searchParams.forEach((value, key) => {
      url.searchParams.delete("show_modal");
    });
    window.history.replaceState({}, "", url.toString());
  },
  directives: {
    "scroll-class": VueScrollClass,
  },
  methods: {
    isSelect: function(num) {
      this.isActive = num;
    },
  },
});

// ページネーションリンクがクリックされたときに show_modal クエリパラメータを除去し、新しいURLにリダイレクト
document.addEventListener("DOMContentLoaded", function() {
  var paginationLinks = document.querySelectorAll(".pagination a");

  paginationLinks.forEach(function(link) {
    link.addEventListener("click", function(event) {
      event.preventDefault();
      var url = new URL(link.href);
      url.searchParams.delete("show_modal");
      window.location.href = url.toString();
    });
  });
});
